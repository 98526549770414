body {
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}